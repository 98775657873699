import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VersionDisplayComponent } from './version-display.component';
import { SharedModule } from '../../shared.module';

@NgModule({
  declarations: [VersionDisplayComponent],
  imports: [CommonModule, SharedModule],
  exports: [VersionDisplayComponent],
})
export class VersionDisplayModule {}
