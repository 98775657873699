import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LowResolutionComponent } from './components/low-resolution/low-resolution.component';
import { AppConfig } from '../../config/app.config';
import { AppRoutes } from '../../classes/route/route-params';

const routes: AppRoutes = [
  {
    component: LowResolutionComponent,
    path: '',
    pathMatch: 'full',
    outlet: 'low-resolution',
    data: {
      animation: AppConfig.LOW_RES_ANIMATION_NAME,
      title: 'LowResolution.Title',
    },
  },
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forChild(routes)],
})
export class LowResolutionRoutingModule {}
