import { Injectable } from '@angular/core';
import { AuthService } from './shared/modules/auth/auth.service';
import { CspService } from './shared/services/csp.service';
import { LanguageService } from './shared/services/language.service';
import { environment } from '../environments/environment';
import { KeycloakAuthService } from './shared/modules/auth/services/keycloak-auth.service';

interface AppInitializerLoadOptions {
  useCSP: boolean;
}

@Injectable()
export class AppInitializer {
  constructor(
    private authService: AuthService,
    private languageService: LanguageService,
    private cspService: CspService,
    private keycloakAuthService: KeycloakAuthService
  ) {}

  load({ useCSP }: AppInitializerLoadOptions = { useCSP: true }): Promise<void[]> {
    this.languageService.init();

    if (useCSP) {
      this.cspService.generateHeader();
    }

    const promises: Promise<void>[] = [];

    if (environment.keyCloakEnabled) {
      promises.push(this.keycloakAuthService.initKeyCloak());
    } else {
      promises.push(this.authService.checkAuth());
    }

    return Promise.all(promises);
  }
}
