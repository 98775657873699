import { Component } from '@angular/core';
import { environment } from '../../../../environments/environment';
import packageJson from '../../../../../package.json';
import { ApiDataService } from '../../services/api-data.service';
import { ApiData } from '../../../classes/api-data';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-version-display',
  templateUrl: './version-display.component.html',
  styleUrls: ['./version-display.component.scss'],
})
export class VersionDisplayComponent {
  private apiData: ApiData;

  constructor(private apiDataService: ApiDataService) {
    this.apiDataService
      .getApiData()
      .pipe(untilDestroyed(this))
      .subscribe((apiData) => {
        this.apiData = { ...apiData };
      });
  }

  getVersion() {
    return `${environment.name}-v${packageJson.version}`;
  }

  getApiData() {
    return this.apiData.apiEnv !== '' && this.apiData.apiVersion !== ''
      ? `${this.apiData?.apiEnv}-v${this.apiData?.apiVersion}`
      : '-';
  }
}
