import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { HighContrastModule } from '../high-contrast/high-contrast.module';
import { SharedModule } from '../../shared.module';
import { VersionDisplayModule } from '../version-display/version-display.module';
import { FormComponentsModule } from '../form-components/form-components.module';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { LanguagePickerModule } from '../language-picker/language-picker.module';
import { MatButtonModule } from '@angular/material/button';
import { MaterialThemeSwitcherModule } from '../material-theme-switcher/material-theme-switcher.module';

@NgModule({
  declarations: [HeaderComponent],
  imports: [
    CommonModule,
    HighContrastModule,
    SharedModule,
    VersionDisplayModule,
    FormComponentsModule,
    MatIconModule,
    MatTooltipModule,
    MatMenuModule,
    MatCheckboxModule,
    LanguagePickerModule,
    MatButtonModule,
    MaterialThemeSwitcherModule,
  ],
  exports: [HeaderComponent],
})
export class HeaderModule {}
