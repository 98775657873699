import { Component, Input, OnInit } from '@angular/core';
import { MaterialThemeSwitcherService } from '../../services/material-theme-switcher.service';
import { FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MainConstants } from '../../../../../config/app.constant';

@UntilDestroy()
@Component({
  selector: 'app-material-theme-switcher',
  templateUrl: './material-theme-switcher.component.html',
  styleUrls: ['./material-theme-switcher.component.scss'],
})
export class MaterialThemeSwitcherComponent implements OnInit {
  @Input() hasIcons: boolean = true;
  isDark = new FormControl(MainConstants.darkThemeOnLaunch);

  constructor(private themeSwitcherService: MaterialThemeSwitcherService) {}

  ngOnInit() {
    this.setDarkTheme(this.isDark.value);

    this.isDark.valueChanges.pipe(untilDestroyed(this)).subscribe({
      next: (isDark) => {
        this.setDarkTheme(isDark);
      },
    });
  }

  setDarkTheme(isDark: boolean) {
    this.themeSwitcherService.setDarkTheme(isDark);
  }
}
