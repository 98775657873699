{
  "name": "angular-starter-template",
  "version": "0.0.1",
  "license": "MIT",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "start-cookie": "node __deploy/serve/serve-cookie.js",
    "start-cookie-sudo": "sudo node __deploy/serve/serve-cookie.js",
    "start-cookie-prod": "node __deploy/serve/serve-cookie.js prod",
    "build": "ng build",
    "build-prod": "ng build --configuration production",
    "build-uat": "ng build --configuration uat",
    "build-dev": "ng build --configuration development",
    "test": "ng test",
    "test:coverage": "ng test --no-watch --code-coverage",
    "test-ci": "ng test --no-watch --source-map=false --browsers=ChromeHeadlessCI",
    "no-task": "",
    "lint": "ng lint",
    "lint-fix": "ng lint --fix",
    "lint-styles": "npx stylelint \"**/*.scss\" --custom-syntax postcss-scss",
    "lint-styles-fix": "npx stylelint --fix \"**/*.scss\" --custom-syntax postcss-scss",
    "sonar": "node sonarqube.js",
    "pretty": "prettier --write \"src/**/*.ts\"",
    "postinstall": "ngcc",
    "docs:json": "compodoc -p ./tsconfig.json -e json -d .",
    "storybook": "npm run -- ng run angular-starter-template:storybook",
    "build-storybook": "npm run -- ng run angular-starter-template:build-storybook",
    "compodoc:build": "compodoc -p tsconfig.doc.json",
    "compodoc:build-and-serve": "compodoc -p tsconfig.doc.json -s",
    "compodoc:serve": "compodoc -s"
  },
  "lint-staged": {
    "*.ts": [
      "npm run pretty"
    ]
  },
  "private": true,
  "dependencies": {
    "@abacritt/angularx-social-login": "^2.1.0",
    "@angular/animations": "^16.2.4",
    "@angular/cdk": "^16.2.3",
    "@angular/common": "^16.2.4",
    "@angular/compiler": "^16.2.4",
    "@angular/core": "^16.2.4",
    "@angular/fire": "^7.6.0",
    "@angular/forms": "^16.2.4",
    "@angular/material": "^16.2.3",
    "@angular/platform-browser": "^16.2.4",
    "@angular/platform-browser-dynamic": "^16.2.4",
    "@angular/router": "^16.2.4",
    "@ng-select/ng-select": "11.1.1",
    "@ngneat/until-destroy": "^10.0.0",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "@popperjs/core": "^2.11.8",
    "@types/google-maps": "^3.2.3",
    "@types/google.maps": "^3.54.0",
    "bootstrap": "^5.3.1",
    "date-fns": "^2.30.0",
    "date-fns-tz": "^2.0.0",
    "firebase": "^9.17.1",
    "keycloak-angular": "14.1.0",
    "keycloak-js": "^22.0.2",
    "lint-staged": "^14.0.1",
    "lodash-es": "^4.17.21",
    "material-design-icons": "^3.0.1",
    "ngx-color-picker": "^14.0.0",
    "ngx-cookie-service": "16.0.1",
    "ngx-device-detector": "6.0.2",
    "ngx-image-cropper": "^7.0.2",
    "ngx-translate-multi-http-loader": "16.0.1",
    "rxjs": "^7.4.0",
    "tslib": "^2.6.2",
    "uuid": "^9.0.1",
    "zone.js": "^0.13.2"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^16.2.1",
    "@angular-eslint/builder": "16.1.2",
    "@angular-eslint/eslint-plugin": "16.1.2",
    "@angular-eslint/eslint-plugin-template": "16.1.2",
    "@angular-eslint/schematics": "16.1.2",
    "@angular-eslint/template-parser": "16.1.2",
    "@angular/cli": "^16.2.1",
    "@angular/compiler-cli": "^16.2.4",
    "@babel/core": "^7.22.17",
    "@compodoc/compodoc": "^1.1.21",
    "@storybook/addon-a11y": "^7.4.1",
    "@storybook/addon-actions": "^7.4.1",
    "@storybook/addon-controls": "^7.4.1",
    "@storybook/addon-essentials": "^7.4.1",
    "@storybook/addon-interactions": "^7.4.1",
    "@storybook/addon-links": "^7.4.1",
    "@storybook/angular": "^7.4.1",
    "@storybook/builder-webpack5": "^7.4.1",
    "@storybook/manager-webpack5": "^6.5.16",
    "@storybook/testing-angular": "^0.0.12",
    "@storybook/testing-library": "0.2.0",
    "@types/jasmine": "~4.3.5",
    "@types/jest": "^29.5.4",
    "@types/lodash-es": "^4.17.9",
    "@types/node": "^20.6.0",
    "@types/uuid": "^9.0.3",
    "@typescript-eslint/eslint-plugin": "^6.7.0",
    "@typescript-eslint/parser": "^6.7.0",
    "babel-loader": "^9.1.3",
    "eslint": "^8.49.0",
    "eslint-config-airbnb-typescript": "17.1.0",
    "eslint-config-prettier": "9.0.0",
    "eslint-plugin-import": "2.28.1",
    "eslint-plugin-prefer-arrow": "1.2.3",
    "eslint-plugin-prettier": "5.0.0",
    "eslint-plugin-storybook": "^0.6.13",
    "jasmine-core": "~5.1.1",
    "jest": "^29.7.0",
    "karma": "~6.4.2",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "postcss": "^8.4.29",
    "postcss-scss": "^4.0.8",
    "prettier": "3.0.3",
    "prettier-eslint": "15.0.1",
    "sonarqube-scanner": "^3.1.0",
    "stylelint": "14.1.0",
    "stylelint-config-prettier": "9.0.3",
    "stylelint-config-standard": "24.0.0",
    "stylelint-scss": "4.0.0",
    "ts-jest": "^29.1.1",
    "typescript": "^5.1.0",
    "webpack": "^5.88.2"
  },
  "engines": {
    "node": "18.x"
  }
}
