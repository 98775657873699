import { HttpBackend } from '@angular/common/http';
import { ITranslationResource, MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';

class HttpLoaderResource implements ITranslationResource {
  suffix = '.json';
  prefix: string;

  constructor(location: string) {
    this.prefix = `./assets/i18n/${location}/`;
  }
}

/** get translate files */
export const HttpMultiLoaderFactory = (http: HttpBackend) => {
  return new MultiTranslateHttpLoader(http, [
    new HttpLoaderResource('auth-forms'),
    new HttpLoaderResource('common'),
    new HttpLoaderResource('home'),
    new HttpLoaderResource('social-login'),
    new HttpLoaderResource('not-found'),
    new HttpLoaderResource('file-upload'),
    new HttpLoaderResource('cookie-popup'),
    new HttpLoaderResource('low-resolution'),
    new HttpLoaderResource('form-components'),
    new HttpLoaderResource('assets-page'),
    new HttpLoaderResource('paginator'),
    new HttpLoaderResource('profile'),
    new HttpLoaderResource('keycloak'),
    new HttpLoaderResource('user-manager'),
  ]);
};
