<app-global-loader></app-global-loader>

<ng-container *ngIf='isBrowserSupported'>
    <app-header [slideHeader]='slideHeader' [hasStickySideBar]='headerHasStickySideBar()'></app-header>

    <app-cookie-popup></app-cookie-popup>

    <app-side-menu
        *ngIf='loggedInUser'
        (toggleMenu)='handleMenuToggle($event)'
        [isBackDropEnabled]='sideMenuOptions.isBackDropEnabled'
        [isFixedBelowHeader]='sideMenuOptions.isFixedBelowHeader'
        [isFullWidth]='sideMenuOptions.isFullWidth'
        [menuItems]='sideMenuItems'
        [closeMenuAfterItemClick]='sideMenuOptions.closeMenuAfterItemClick'
        [hasStickySideBar]='sideMenuOptions.hasStickySideBar'
        [slideMode]='sideMenuOptions.slideMode'>
    </app-side-menu>

    <div [class.push-content]='slideContent.Push'
         [class.shrink-content]='slideContent.Shrink && loggedInUser'
         [class.has-sticky-side-bar]='loggedInUser && sideMenuOptions.hasStickySideBar'
         [class.mobile]='isMobile'
         class='main-content mat-app-background'>
        <div [@routerTransition]='prepareRouterTransition(o)'>
            <router-outlet #o='outlet'></router-outlet>
        </div>
    </div>
</ng-container>
