import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { AuthService } from '../auth.service';
import { MatModalService } from '../../mat-modal/mat-modal.service';
import { ConfirmModalComponent } from '../../mat-modal/modals/confirm-modal/confirm-modal.component';
import { ConfirmModalData } from '../../mat-modal/modals/confirm-modal/classes/confirm-modal-data';
import { DebugHelperUtil } from '../../../utils/debug-helper.util';
import { KeycloakJwtToken } from '../../../../classes/keycloak-jwt-token';

@Injectable({
  providedIn: 'root',
})
export class KeycloakAuthService {
  constructor(
    private keycloak: KeycloakService,
    private authService: AuthService,
    private matModalService: MatModalService
  ) {}

  initKeyCloak(): Promise<void> {
    this.log('init keycloak');

    return new Promise((resolve) => {
      this.handleKeyCloakEvents();

      this.keycloak
        .init({
          config: environment.keyCloak,
          initOptions: {
            onLoad: 'check-sso',
            silentCheckSsoRedirectUri: window.location.origin + '/assets/keycloack/silent-check-sso.html',
          },
          bearerExcludedUrls: ['/assets'],
        })
        .then(
          () => {
            this.log('handle auth success');
            this.handleAuthSuccess().then(resolve);
          },
          (result: unknown) => {
            this.log('handle auth ERROR');
            this.handleAuthError(resolve, result);
          }
        );
    });
  }

  logout() {
    this.keycloak.logout();
  }

  private handleKeyCloakEvents() {
    this.keycloak.keycloakEvents$.subscribe({
      next: (event) => {
        if (event.type === KeycloakEventType.OnTokenExpired) {
          this.refreshToken();
        }
      },
    });
  }

  private async handleAuthSuccess(): Promise<void> {
    this.log('load user profile');

    try {
      const user = await this.keycloak.loadUserProfile();
      const token = await this.keycloak.getToken();

      const decodedToken = this.authService.getDecodedToken(token) as KeycloakJwtToken;

      this.authService.setUser({
        id: user.id,
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
        roles: decodedToken.realm_access.roles,
      });
    } catch (e) {
      this.log('failed to load user or token');
      this.authService.logout();
    }
  }

  private handleAuthError(resolve: () => void, result: unknown): void {
    this.authService.logout();
    resolve();

    setTimeout(() => {
      this.showAuthErrorModal(result);
    });
  }

  private showAuthErrorModal(result: unknown) {
    this.matModalService
      .openDialog<ConfirmModalData>(ConfirmModalComponent, {
        data: {
          title: 'KeyCloak.AuthErrorModal.Title',
          messages: [
            { key: 'KeyCloak.AuthErrorModal.Message.1' },
            { key: 'KeyCloak.AuthErrorModal.Message.2' },
            { key: 'KeyCloak.AuthErrorModal.Message.3' },
            {
              key: 'KeyCloak.AuthErrorModal.Message.4',
              args: { errorArgs: result ? JSON.stringify(result) : 'undefined' },
            },
            { key: 'KeyCloak.AuthErrorModal.Message.5' },
            { key: 'KeyCloak.AuthErrorModal.Message.6' },
          ],
          icon: 'error',
        },
      })
      .subscribe(() => {
        this.keycloak.logout();
      });
  }

  private refreshToken() {
    this.keycloak.updateToken(20);
  }

  private log(...args: unknown[]) {
    DebugHelperUtil.logIfDebug('KEYCLOAK SERVICE: ', args);
  }
}
