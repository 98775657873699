import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { environment } from '../../../../environments/environment';
import packageJson from '../../../../../package.json';
import { v4 as uuidv4 } from 'uuid';
import { GlobalLoaderService } from '../global-loader/services/global-loader.service';
import { CookieStorageService } from '../auth/services/cookie.storage.service';
import { AuthConfig } from '../../../config/auth.config';
import { apiConfig } from '../../../config/api.config';
import { LanguageService } from '../../services/language.service';
import { MainConstants } from '../../../config/app.constant';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  private backendUrlWithoutCredentials: string[] = [environment.testDevApiUrl];

  constructor(
    private loader: GlobalLoaderService,
    private languageService: LanguageService,
    private authService: AuthService,
    private cookieStorageService: CookieStorageService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let requestClone = request.clone();

    // TODO: THIS IS NOT A FINAL SOLUTION FOR THIS PROBLEM! WE NEED A COMPREHENSIVE SOLUTION!

    if (this.hasCredentials(request.url)) {
      requestClone = this.setRequestHeaders(requestClone);
      this.loader.showLoaderForUrl(requestClone.url);
    }

    return next.handle(requestClone);
  }

  private hasCredentials(url: string): boolean {
    return !this.backendUrlWithoutCredentials.some((apiUrl) => url.startsWith(apiUrl));
  }

  private setRequestHeaders(req: HttpRequest<unknown>): HttpRequest<unknown> {
    const isLogin = req.urlWithParams.startsWith(`${environment.apiUrl}${apiConfig.url.authLogin}`);

    const headers: { [key: string]: string } = {
      'Accept-Language': this.languageService.currentLang,
      'app-version': packageJson.version,
      'app-env': environment.name,
      platform: MainConstants.platformName,
      'x-request-id': uuidv4(),
    };

    if (!environment.keyCloakEnabled && !isLogin) {
      if (!environment.useCookieAuth) {
        headers[AuthConfig.AUTHORIZATION_HEADER_KEY] = `Bearer ${this.authService.getAuthToken()}`;
      } else {
        headers[AuthConfig.CSRF_TOKEN_HEADER_KEY] = this.cookieStorageService.getItem(AuthConfig.CSRF_TOKEN_KEY);
      }
    }

    return req.clone({
      setHeaders: headers,
      withCredentials: true,
    });
  }
}
