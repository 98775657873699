import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideMenuComponent } from './side-menu.component';
import { SharedModule } from '../../shared.module';
import { VersionDisplayModule } from '../version-display/version-display.module';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [SideMenuComponent],
  imports: [CommonModule, SharedModule, VersionDisplayModule, MatIconModule, MatButtonModule],
  exports: [SideMenuComponent],
})
export class SideMenuModule {}
