<div [class.fixed-below-header]="isFixedBelowHeader"
     [class.full-width]="isFullWidth"
     [class.open]="isMenuOpen"
     [class.sticky-bar]="hasStickySideBar"
     [class.mobile]="isMobile"
     class="sidenav mat-app-background">
    <div class="side-menu-content">

        <div *ngIf="hasStickySideBar" class="d-flex justify-content-center">
            <button *ngIf="!isMenuOpen" (click)="sideMenuService.toggleSideMenu(); false"
                    class="side-menu-open-icon"
                    mat-icon-button color="primary">
                <mat-icon>menu</mat-icon>
            </button>

        </div>

        <div *ngIf="hasStickySideBar" class="d-flex justify-content-end">
            <button *ngIf="isMenuOpen" (click)="sideMenuService.toggleSideMenu(); true"
                    class="side-menu-close-icon"
                    mat-icon-button color="primary">
                <mat-icon>close</mat-icon>
            </button>
        </div>

        <div *ngFor="let group of menuItems; let groupIndex = index;" class="side-menu-group pt-1">
            <div class="side-menu-group-title">
                <div *ngIf="!hasStickySideBar || isMenuOpen" [@fadeInOut]>
                    {{group.groupTitle | translate}}
                </div>
            </div>

            <div (mouseleave)="unHoverAllItem()" class="d-flex side-menu-items-container">
                <div class="has-sticky-bar">
                    <a *ngFor="let item of group.items; let itemIndex = index"
                       [class.has-sticky-bar]="hasStickySideBar && !isMenuOpen"
                       [title]="item.title | translate"
                       (click)="onClick(item); false"
                       [id]="'item-menu-' + groupIndex + '_' + itemIndex"
                       (mouseover)="hoverItem(groupIndex, itemIndex)"
                       [class.active]="getItemIsActive(item)"
                       class="side-menu-item menu-item-icon" href="">

                        <div class="d-flex align-items-center">
                            <mat-icon *ngIf="item.icon">{{item.icon}}</mat-icon>
                        </div>
                    </a>
                </div>
                <div class="w-100 flex-grow-1">
                    <a (click)="onClick(item); false"
                       *ngFor="let item of group.items; let itemIndex = index"
                       [class.active]='getItemIsActive(item)'
                       [id]="'item-' + groupIndex + '_' + itemIndex"
                       (mouseover)="hoverItem(groupIndex, itemIndex)"
                       class="side-menu-item menu-item-text"
                       href="">
                        <div *ngIf="!hasStickySideBar || isMenuOpen" [@fadeInOut]
                             class="ms-2">
                            {{item.title | translate}}
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="isMenuOpen" class="bottom-section">
        <app-version-display class="app-version"></app-version-display>
    </div>
</div>

<div (click)="sideMenuService.toggleSideMenu(false)"
     *ngIf="isBackDropEnabled"
     [class.fixed-below-header]="isFixedBelowHeader"
     [class.open]="isMenuOpen"
     [class.mobile]="isMobile"
     class="side-menu-backdrop">
</div>
