import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../services/language.service';
import { Language, Languages } from './language.config';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-language-picker',
  templateUrl: './language-picker.component.html',
  styleUrls: ['./language-picker.component.scss'],
})
export class LanguagePickerComponent implements OnInit {
  @Input() showSelectedLangLabel = true;

  currentLang: Language;
  availableLanguages = Languages;

  constructor(
    private translate: TranslateService,
    private languageService: LanguageService
  ) {}

  ngOnInit(): void {
    this.currentLang = this.languageService.currentLang;

    this.translate.onLangChange.pipe(untilDestroyed(this)).subscribe({
      next: (langChangeEvent) => (this.currentLang = langChangeEvent.lang as Language),
    });
  }

  changeLang(lang: Language): void {
    this.languageService.setLanguage(lang);
  }

  get currentLangName(): string {
    const currentLangObject = this.availableLanguages.find((lang) => {
      return lang.slug === this.currentLang;
    });

    return currentLangObject?.label;
  }
}
