import { catchError, delay, filter, switchMap, take, tap } from 'rxjs/operators';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { GlobalLoaderService } from '../global-loader/services/global-loader.service';
import { environment } from '../../../../environments/environment';
import { HttpService } from './http.service';
import { apiConfig } from '../../../config/api.config';
import { ApiData } from '../../../classes/api-data';
import { AuthConfig } from '../../../config/auth.config';
import { LocalStorageService } from '../auth/services/local.storage.service';
import { LoginHeaderResponse } from '../auth/classes/login-header-response';
import { LoginCookieResponse } from '../auth/classes/login-cookie-response';
import { ApiDataService } from '../../services/api-data.service';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<unknown> = new BehaviorSubject<unknown>(null);

  apiData: ApiData;

  constructor(
    private loader: GlobalLoaderService,
    private authService: AuthService,
    private httpService: HttpService,
    private localStorageService: LocalStorageService,
    private apiDataService: ApiDataService
  ) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const requestClone = req.clone();

    return next.handle(requestClone).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          const apiVersion = event.headers.get('api-version') ?? '';
          const apiEnv = event.headers.get('api-env') ?? '';

          if (this.apiData?.apiVersion !== apiVersion || this.apiData?.apiEnv !== apiEnv) {
            this.apiDataService.setApiData({ apiVersion, apiEnv });
          }

          this.loader.hideLoaderForUrl(requestClone.url);
        }
      }),
      catchError((error: HttpErrorResponse) => {
        this.loader.hideLoaderForUrl(requestClone.url);

        if (error instanceof HttpErrorResponse) {
          return this.handleError(error, requestClone);
        } else {
          return throwError(() => error);
        }
      })
    );
  }

  private handleError(err: HttpErrorResponse, request: HttpRequest<unknown>): Observable<HttpEvent<unknown>> {
    if (err.status === 401) {
      return this.handle401Error(request);
    }

    alert(`${err.status}`);

    return throwError(() => err);
  }

  private handle401Error(request: HttpRequest<unknown>): Observable<HttpEvent<unknown>> {
    if (this.needToRefresh(request)) {
      return this.refreshToken(request);
    }

    this.authService.logout(true);

    return throwError(() => null);
  }

  private needToRefresh(request: HttpRequest<unknown>) {
    const skippedUrls = [apiConfig.url.authLogin, apiConfig.url.authRefresh];
    return !skippedUrls.some((route) => request.url.startsWith(`${environment.apiUrl}${route}`));
  }

  private refreshToken(request: HttpRequest<unknown>): Observable<HttpEvent<unknown>> {
    if (this.isRefreshing) {
      return this.refreshTokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap(() => {
          return this.httpService.sendRequest(request);
        })
      );
    }

    this.isRefreshing = true;
    this.refreshTokenSubject.next(null);

    return of(null).pipe(
      delay(300),
      switchMap(() => {
        return this.httpService
          .post<LoginHeaderResponse | LoginCookieResponse>(
            apiConfig.url.authRefresh,
            (environment.useCookieAuth
              ? {}
              : {
                  refreshToken: this.localStorageService.getItem(AuthConfig.REFRESH_TOKEN_STORAGE_KEY),
                }) as LoginCookieResponse
          )
          .pipe(
            switchMap((response: LoginHeaderResponse | LoginCookieResponse) => {
              if (!environment.useCookieAuth) {
                this.authService.setTokensToLocalStorage(response as LoginHeaderResponse);
              }
              this.isRefreshing = false;
              this.refreshTokenSubject.next('token created');
              return this.httpService.sendRequest(request);
            }),
            catchError((error: HttpErrorResponse) => {
              return throwError(() => error);
            })
          );
      })
    );
  }
}
