import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { LowResolutionComponent } from './pages/low-resolution/components/low-resolution/low-resolution.component';

/*eslint-disable*/
const routes: Routes = [
  {
    path: 'auth-user',
    loadChildren: () => import('./pages/auth-user/auth-user.module').then((mod) => mod.AuthUserModule),
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./pages/reset-password/reset-password.module').then((mod) => mod.ResetPasswordModule),
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then((mod) => mod.HomeModule),
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then((mod) => mod.ProfileModule),
  },
  {
    path: 'components',
    loadChildren: () => import('./pages/components/components.module').then((mod) => mod.ComponentsModule),
  },
  {
    path: 'user-manager',
    loadChildren: () => import('./pages/user-manager/user-manager.module').then((mod) => mod.UserManagerModule),
  },
  {
    path: 'form-fields-demo',
    loadChildren: () =>
      import('./pages/form-fields-demo/form-fields-demo.module').then((mod) => mod.FormFieldsDemoModule),
  },
  {
    path: 'breadcrumb-demo',
    loadChildren: () =>
      import('./pages/breadcrumb-test/breadcrumb-test.module').then((mod) => mod.BreadcrumbTestModule),
  },
  {
    path: 'form-stepper-example',
    loadChildren: () => import('./pages/form-stepper/form-stepper.module').then((mod) => mod.FormStepperModule),
  },
  {
    path: 'assets',
    loadChildren: () => import('./pages/assets-page/assets-page.module').then((mod) => mod.AssetsPageModule),
  },
  {
    path: 'tab-url-demo',
    loadChildren: () => import('./pages/tab-url-demo/tab-url-demo.module').then((mod) => mod.TabUrlDemoModule),
  },
  { path: '', redirectTo: '/auth-user/login', pathMatch: 'full' },
  { path: 'low-resolution', component: LowResolutionComponent },
  { path: '**', component: NotFoundComponent },
];

/** Main routing module */
export const AppRoutingModule = RouterModule.forRoot(routes, {});
