import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanguagePickerComponent } from './language-picker.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [LanguagePickerComponent],
  imports: [CommonModule, TranslateModule, MatMenuModule, MatTooltipModule, MatButtonModule],
  exports: [LanguagePickerComponent],
})
export class LanguagePickerModule {}
