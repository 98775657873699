import { Component, OnDestroy, OnInit, HostBinding, ChangeDetectorRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { filter, first, map } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SideMenuItems } from './shared/modules/side-menu/classes/side-menu-items';
import { SideMenuOptions } from './shared/modules/side-menu/classes/side-menu-options';
import { routerTransition } from '../animations';
import { LowResolutionService } from './pages/low-resolution/services/low-resolution.service';
import { AuthService } from './shared/modules/auth/auth.service';
import { User } from './classes/user/user';
import { BaseComponent } from './shared/modules/base-component/base.component';
import { SideMenuItem } from './shared/modules/side-menu/classes/side-menu-item';
import { SideMenuToggleEvent } from './shared/modules/side-menu/classes/side-menu-toggle-event';
import { AppUrlConfig } from './config/app-url.config';
import { SideMenuSlideType } from './shared/modules/side-menu/classes/side-menu-slide-type';
import { SideMenuMode } from './shared/modules/side-menu/classes/side-menu-mode';
import { MaterialThemeSwitcherService } from './shared/modules/material-theme-switcher/services/material-theme-switcher.service';
import { OverlayContainer } from '@angular/cdk/overlay';

/** Main component */
@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routerTransition],
})
export class AppComponent extends BaseComponent implements OnInit, OnDestroy {
  sideMenuOptions = new SideMenuOptions();
  slideContent: SideMenuSlideType = {};
  slideHeader: SideMenuSlideType = {};
  sideMenuItems: SideMenuItems[];

  loggedInUser: User;

  @HostBinding('class') className = '';
  isDarkTheme: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    private lowResolutionService: LowResolutionService,
    private authService: AuthService,
    private themeSwitcher: MaterialThemeSwitcherService,
    private overlay: OverlayContainer,
    private cd: ChangeDetectorRef
  ) {
    super();
    this.slideContent = { [SideMenuMode.Shrink]: true };
  }

  ngOnInit() {
    this.initLoggedInUser();
    this.initSideMenuItems();
    this.listenForNavigationEndChanges();
    this.listenResolutionChanges();
    this.listenToThemeValueChanges();

    return super.ngOnInit();
  }

  handleMenuToggle(event: SideMenuToggleEvent): void {
    this.slideHeader = event.header;
    this.slideContent = event.content;
  }

  headerHasStickySideBar(): boolean {
    return this.sideMenuOptions.hasStickySideBar && !this.sideMenuOptions.isFixedBelowHeader;
  }

  prepareRouterTransition(outlet: RouterOutlet): string {
    return outlet.activatedRouteData.animation as string;
  }

  private initLoggedInUser(): void {
    this.authService.loggedInUserChange.pipe(untilDestroyed(this)).subscribe((user) => {
      this.loggedInUser = user;
    });
  }

  private initSideMenuItems(): void {
    this.sideMenuItems = [
      {
        groupTitle: 'Menu.ExistingPages',
        items: [
          new SideMenuItem('Menu.Home', true, 'home', 'home'),
          new SideMenuItem('Menu.Profile', true, 'person', 'profile'),
          new SideMenuItem('Menu.Components', true, 'apps', 'components'),
          new SideMenuItem('Menu.Users', true, 'people', 'user-manager'),
          new SideMenuItem('Menu.FormFields', true, 'list_alt', 'form-fields-demo'),
          new SideMenuItem('Menu.BreadCrumb', true, 'swap_horiz', `${AppUrlConfig.BREADCRUMB_TEST_PAGE_URL}`),
          new SideMenuItem('Menu.FormStepper', true, ' linear_scale', 'form-stepper-example'),
          new SideMenuItem('Menu.Tab', true, 'tab', 'tab-url-demo'),
          new SideMenuItem('Menu.Assets', true, 'build', 'assets/global'),
        ],
      },
    ];
  }

  private listenToThemeValueChanges(): void {
    this.themeSwitcher
      .getThemeState()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (darkMode) => {
          this.isDarkTheme = darkMode;
          const darkClassName = 'dark-mode';
          this.className = darkMode ? darkClassName : '';
          if (darkMode) {
            this.overlay.getContainerElement().classList.add(darkClassName);
          } else {
            this.overlay.getContainerElement().classList.remove(darkClassName);
          }
        },
      });

    this.cd.detectChanges();
  }

  private listenForNavigationEndChanges(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let child = this.route.firstChild;
          while (child) {
            if (child.firstChild) {
              child = child.firstChild;
            } else if (child.snapshot) {
              return child.snapshot;
            } else {
              return null;
            }
          }

          return null;
        }),
        untilDestroyed(this)
      )
      .subscribe((snapshot: ActivatedRouteSnapshot) => {
        this.setTitle(snapshot);
      });
  }

  private setTitle(snapshot: ActivatedRouteSnapshot): void {
    if (snapshot?.data && snapshot?.data.title) {
      this.translate
        .get(snapshot.data.title as string | string[])
        .pipe(first())
        .subscribe((translated) => {
          this.titleService.setTitle(translated as string);
        });
    }
  }

  private listenResolutionChanges(): void {
    if (this.lowResolutionService.checkResolutionOnInit()) {
      this.lowResolutionService.showLowResPage();
    }
    this.lowResolutionService.watchLowResolution$.pipe(untilDestroyed(this)).subscribe((limitReached: boolean) => {
      if (limitReached) {
        this.lowResolutionService.showLowResPage();
      } else {
        this.lowResolutionService.hideLowResPage();
      }
    });
  }

  /* eslint-disable @typescript-eslint/member-ordering */
  ngOnDestroy() {
    return super.ngOnDestroy();
  }
}
