import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { MainConstants } from '../../../../config/app.constant';

@Injectable({
  providedIn: 'root',
})
export class MaterialThemeSwitcherService {
  private isDarkTheme$ = new BehaviorSubject<boolean>(MainConstants.darkThemeOnLaunch);

  setDarkTheme(isDark: boolean) {
    this.isDarkTheme$.next(isDark);
  }

  getThemeState(): Observable<boolean> {
    return this.isDarkTheme$.asObservable();
  }
}
