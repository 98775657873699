import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SideMenuService } from './side-menu.service';
import { SideMenuMode } from './classes/side-menu-mode';
import { SideMenuToggleEvent } from './classes/side-menu-toggle-event';
import { SideMenuItems } from './classes/side-menu-items';
import { fadeInOutTransition } from '../../../animations';
import { BaseComponent } from '../base-component/base.component';
import { SideMenuItem } from './classes/side-menu-item';

@UntilDestroy()
@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
  animations: [fadeInOutTransition('0.3s')],
})
export class SideMenuComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() isFixedBelowHeader = true;
  @Input() isFullWidth = false;
  @Input() isBackDropEnabled = true;
  @Input() slideMode = SideMenuMode.Push;
  @Input() menuItems: SideMenuItems[];
  @Input() closeMenuAfterItemClick = true;
  @Input() hasStickySideBar = true;

  @Output() toggleMenu: EventEmitter<SideMenuToggleEvent> = new EventEmitter<SideMenuToggleEvent>();

  isMenuOpen = true;

  constructor(
    public sideMenuService: SideMenuService,
    private router: Router,
    private renderer: Renderer2
  ) {
    super();
  }
  /**
   * Azért van szükség a return-re, mivel az ős osztályban definiáltuk,
   * hogy kötelező visszatérnie egy adott értékkel. Ezt úgy tudod könnyel elérni,
   * ha meghívod az ős lifecycle hook-ját. Ezzel kényszerítjük ki, hogy az ős
   * lifecycle hook-ja mindig meg legyen hívva. Ellenkező esetben hibát fog dobni.
   */
  ngOnInit() {
    const superCalled = super.ngOnInit();
    this.handleSideMenuToggle();
    return superCalled;
  }

  onClick(item: SideMenuItem) {
    if (!item.url) {
      item.onClick();
    } else {
      this.router.navigateByUrl(item.url);
    }

    if (this.closeMenuAfterItemClick) {
      this.sideMenuService.toggleSideMenu(false);
    }
  }

  getItemIsActive(item: SideMenuItem): boolean {
    return this.router.url && item.url && this.router.url.includes(item.url);
  }

  hoverItem(groupIndex: number, itemIndex: number) {
    this.unHoverAllItem();
    this.addHoverToItem(groupIndex, itemIndex);
  }

  unHoverAllItem() {
    this.removeHoverByClass('menu-item-icon');
    this.removeHoverByClass('menu-item-text');
  }

  private addHoverToItem(groupIndex: number, itemIndex: number) {
    const itemIdIndex = `${groupIndex}_${itemIndex}`;

    this.renderer.addClass(document.getElementById('item-menu-' + itemIdIndex), 'hovered');
    this.renderer.addClass(document.getElementById('item-' + itemIdIndex), 'hovered');
  }

  private removeHoverByClass(className: string) {
    const items = document.getElementsByClassName(className);
    for (let i = 0; i < items.length; i++) {
      this.renderer.removeClass(items.item(i), 'hovered');
    }
  }

  private handleSideMenuToggle() {
    this.sideMenuService
      .getSideMenuSubject()
      .pipe(untilDestroyed(this))
      .subscribe((isOpen) => {
        this.isMenuOpen = isOpen === true || isOpen === false ? isOpen : !this.isMenuOpen;
        if (this.slideMode !== SideMenuMode.Overlay) {
          this.toggleMenu.emit({
            header: {
              [this.slideMode]: this.isFixedBelowHeader ? false : this.isMenuOpen,
            },
            content: {
              [this.slideMode]: this.isMenuOpen,
            },
          });
        }
      });
  }
}
