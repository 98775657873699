import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { HttpBackend, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { HttpModule } from './shared/modules/http/http.module';
import { GlobalLoaderModule } from './shared/modules/global-loader/global-loader.module';
import { HeaderModule } from './shared/modules/header/header.module';
import { MatModalModule } from './shared/modules/mat-modal/mat-modal.module';
import { HttpMultiLoaderFactory } from './shared/utils/http-multi-loader.util';
import { AppInitializer } from './app.initializer';
import { SideMenuModule } from './shared/modules/side-menu/side-menu.module';
import { AppInjector } from './app.injector.service';
import { BaseComponentModule } from './shared/modules/base-component/base-component.module';
import { LowResolutionModule } from './pages/low-resolution/low-resolution.module';
import { CookiePopupModule } from './shared/modules/cookie-popup/cookie-popup.module';
import { CookieService } from 'ngx-cookie-service';
import { NotFoundModule } from './pages/not-found/not-found.module';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from '../environments/environment';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { KeycloakAngularModule } from 'keycloak-angular';

export const initApp = (initializer: AppInitializer) => {
  return () => initializer.load();
};

/** Main module, imports app wide modules, providers, bootstrap */
@NgModule({
  declarations: [AppComponent],
  imports: [
    HeaderModule,
    SideMenuModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    HttpModule,
    NotFoundModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpMultiLoaderFactory,
        deps: [HttpBackend],
      },
    }),
    GlobalLoaderModule,
    MatModalModule,
    BaseComponentModule,
    LowResolutionModule.forRoot(),
    CookiePopupModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFireStorageModule,
    KeycloakAngularModule,
  ],
  providers: [
    AppInitializer,
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      deps: [AppInitializer],
      multi: true,
    },
    CookieService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(injector: Injector) {
    AppInjector.setInjector(injector);
  }
}
