import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialThemeSwitcherComponent } from './components/material-theme-switcher/material-theme-switcher.component';
import { FormComponentsModule } from '../form-components/form-components.module';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [MaterialThemeSwitcherComponent],
  exports: [MaterialThemeSwitcherComponent],
  imports: [CommonModule, FormComponentsModule, MatIconModule, ReactiveFormsModule],
})
export class MaterialThemeSwitcherModule {}
