import { Component } from '@angular/core';
// eslint-disable-next-line
import {LowResolutionService} from '../../services/low-resolution.service';
import { AppConfig } from '../../../../config/app.config';

@Component({
  selector: 'app-low-resolution',
  templateUrl: './low-resolution.component.html',
  styleUrls: ['./low-resolution.component.scss'],
})
export class LowResolutionComponent {
  limit = AppConfig.LOW_RESOLUTION_LIMIT;

  constructor(private lowResolutionService: LowResolutionService) {}

  onDismissLowResMode(): void {
    this.lowResolutionService.dismissLowResMode();
  }
}
