<div class="h-100 d-flex align-items-center justify-content-center">
    <mat-icon *ngIf="hasIcons" [color]="'primary'">brightness_low</mat-icon>

    <app-slide-toggle
        [formControl]="isDark"
        [showError]="false"
        class="mx-2">
    </app-slide-toggle>

    <mat-icon *ngIf="hasIcons" [color]="'primary'">brightness_2</mat-icon>
</div>
