import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent, merge, Observable } from 'rxjs';
import { debounceTime, filter, map } from 'rxjs/operators';
import { ComponentType, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../../../environments/environment';
// eslint-disable-next-line
import {LOW_RESOLUTION_COMPONENT} from '../classes/low-resolution.injection-token';
import { AppConfig } from '../../../config/app.config';
import { LowResolutionComponent } from '../components/low-resolution/low-resolution.component';

@Injectable()
export class LowResolutionService {
  private lowResolutionModeEnabled = environment.lowResolutionEnabled;
  private overlayRef: OverlayRef;
  private lowResChanges$ = new BehaviorSubject<boolean>(this.lowResolutionModeEnabled);

  constructor(
    private overlay: Overlay,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOW_RESOLUTION_COMPONENT) private lowResolutionComponent: ComponentType<LowResolutionComponent>
  ) {
    this.overlayRef = this.overlay.create({
      width: '100vw',
      height: '100vh',
    });
    this.checkResolutionOnInit();
  }

  get watchLowResolution$(): Observable<boolean> {
    const resizeEvent$ = fromEvent(window, 'resize');
    const loadEvent$ = fromEvent(window, 'load');
    return merge(resizeEvent$, loadEvent$).pipe(
      filter(() => this.lowResolutionModeEnabled),
      debounceTime(300),
      map(() => this.checkLowResLimitReached())
    );
  }

  getLowResChanges(): Observable<boolean> {
    return this.lowResChanges$.asObservable();
  }

  getIsLowResModeEnabled(): boolean {
    return this.lowResolutionModeEnabled;
  }

  setLowResModeEnabledState(isEnabled: boolean): void {
    this.lowResolutionModeEnabled = isEnabled;
    this.lowResChanges$.next(isEnabled);
  }

  dismissLowResMode() {
    this.lowResolutionModeEnabled = false;
    this.lowResChanges$.next(false);
    this.hideLowResPage();
  }

  showLowResPage(): void {
    if (!this.overlayRef.hasAttached()) {
      this.overlayRef.attach(new ComponentPortal(this.lowResolutionComponent));
    }
  }

  hideLowResPage(): void {
    if (this.overlayRef.hasAttached()) {
      this.overlayRef.detach();
    }
  }

  checkResolutionOnInit(): boolean {
    if (!this.lowResolutionModeEnabled) {
      return false;
    }
    return this.checkLowResLimitReached();
  }

  private checkLowResLimitReached(): boolean {
    const currentWidth = this.document.body.clientWidth;
    return currentWidth < AppConfig.LOW_RESOLUTION_LIMIT;
  }
}
