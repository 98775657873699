<button
    mat-button
    [matMenuTriggerFor]="flags"
    [matTooltip]="'Language.ChooseLanguage' | translate"
    class="d-flex align-items-center"
    id="language-picker-main-button">

    <div>
        <img
            class="flag-adjustment me-2"
            [src]="'assets/image/flags/' + currentLang + '.svg'"
            [alt]="currentLang + '_flag'">
        <span *ngIf="showSelectedLangLabel">{{currentLangName | translate}}</span>
    </div>

</button>

<mat-menu #flags="matMenu">
    <button
        *ngFor="let languages of availableLanguages"
        mat-menu-item
        (click)="changeLang(languages.slug)"
        class="d-flex align-items-center">
        <img class="flag-adjustment me-2" [src]="languages.src" [alt]="languages.alt">
        <span>{{languages.label | translate}}</span>
    </button>
</mat-menu>
