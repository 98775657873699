import { Component, OnInit } from '@angular/core';
import { HighContrastService } from './high-contrast.service';

/**
 * Implement high-contrast. Contains the high-contrast button
 */
@Component({
  selector: 'app-high-contrast',
  templateUrl: './high-contrast.component.html',
  styleUrls: ['./high-contrast.component.scss'],
})
export class HighContrastComponent implements OnInit {
  themeKey = 'high-contrast-theme';
  theme = false;
  enable: boolean;

  constructor(private highContrastService: HighContrastService) {}

  /**
   * Initialize mode from local Storage
   */
  ngOnInit() {
    const theme = localStorage.getItem(this.themeKey) === 'true';
    this.theme = theme || false;
    this.setTheme();
    this.setHighContrast(this.theme);
  }

  /**
   * Switch high-contrast mode
   */
  switchAccessibility() {
    this.theme = !this.theme;
    this.setTheme();
    this.setHighContrast(this.theme);
  }

  /**
   * Send high-contrast mode change event, other components can listen for this event
   */
  setHighContrast(value: boolean) {
    this.highContrastService.setHighContrastState(value);
  }

  /**
   * Set high contrast mode theme,
   * append high-contrast.css to header if mode is enabled.
   * remove css from header if mode is not enabled
   * set selected mode to local storage
   */
  setTheme() {
    if (!this.theme) {
      if (document.getElementById(this.themeKey)) {
        const child = document.getElementById(this.themeKey);
        child.parentNode.removeChild(child);
      }

      this.theme = false;
      localStorage.setItem(this.themeKey, JSON.stringify(false));
    } else {
      const node = document.createElement('link');
      node.href = '/assets/css/high-contrast.css';
      node.rel = 'stylesheet';
      node.id = this.themeKey;
      document.getElementsByTagName('head')[0].appendChild(node);

      this.theme = true;
      localStorage.setItem(this.themeKey, JSON.stringify(true));
    }
  }

  get highContrastImage() {
    return `/assets/image/high-contrast/high_contrast_${this.theme ? 'normal' : 'black_white'}.svg`;
  }
}
