<div
    [class.push-content]='slideHeader.Push'
    [class.shrink-content]='slideHeader.Shrink'
    [class.has-sticky-side-bar]='hasStickySideBar'
    [class.mobile]='isMobile'
    class='app-header mat-app-background'>


    <div class="app-header-content">
        <app-high-contrast class='d-block me-2'></app-high-contrast>
        <label for='low-res-mode' class='me-2'>Low res mode enabled: </label>
        <mat-checkbox
            id='low-res-mode'
            [formControl]='lowResControl'
            [color]="'primary'">
        </mat-checkbox>
    </div>

    <div class="theme-switcher">
        <app-material-theme-switcher></app-material-theme-switcher>
    </div>

    <div class="language-picker">
        <app-language-picker></app-language-picker>
    </div>

    <div class="d-flex px-1 profile-menu align-items-center d-flex h-70"
         *ngIf='loggedInUser'>

        <button
            mat-button
            id="profile-menu-trigger-button"
            [matMenuTriggerFor]="menu">
            {{ loggedInUser.email }}
        </button>

        <mat-menu #menu="matMenu">
            <div mat-menu-item
                 (click)="logout()">
                {{ 'Menu.LogOut' | translate }}
            </div>
        </mat-menu>
    </div>
</div>
