import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CookiePopupComponent } from './cookie-popup.component';
import { CookiePopupModalComponent } from './components/cookie-popup-modal/cookie-popup-modal.component';
import { MatModalModule } from '../mat-modal/mat-modal.module';
import { SharedModule } from '../../shared.module';

@NgModule({
  declarations: [CookiePopupComponent, CookiePopupModalComponent],
  imports: [CommonModule, MatModalModule, SharedModule],
  exports: [CookiePopupComponent],
})
export class CookiePopupModule {}
