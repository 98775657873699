import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LowResolutionService } from './services/low-resolution.service';
import { LowResolutionComponent } from './components/low-resolution/low-resolution.component';
import { LowResolutionRoutingModule } from './low-resolution-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { LOW_RESOLUTION_COMPONENT } from './classes/low-resolution.injection-token';

@NgModule({
  declarations: [LowResolutionComponent],
  imports: [CommonModule, LowResolutionRoutingModule, SharedModule],
  exports: [LowResolutionComponent],
})
export class LowResolutionModule {
  static forRoot(): ModuleWithProviders<LowResolutionModule> {
    return {
      ngModule: LowResolutionModule,
      providers: [LowResolutionService, { provide: LOW_RESOLUTION_COMPONENT, useValue: LowResolutionComponent }],
    };
  }
}
