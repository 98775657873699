import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SideMenuService } from '../side-menu/side-menu.service';
import { User } from '../../../classes/user/user';
import { AuthService } from '../auth/auth.service';
import { BaseComponent } from '../base-component/base.component';
import { LowResolutionService } from '../../../pages/low-resolution/services/low-resolution.service';
import { SideMenuSlideType } from '../side-menu/classes/side-menu-slide-type';
import { environment } from '../../../../environments/environment';
import { KeycloakAuthService } from '../auth/services/keycloak-auth.service';

@UntilDestroy()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() slideHeader: SideMenuSlideType = {};
  @Input() hasStickySideBar: boolean;

  loggedInUser: User;
  lowResControl = new FormControl<boolean>(null);

  constructor(
    public sideMenuService: SideMenuService,
    private authService: AuthService,
    private keycloakService: KeycloakAuthService,
    private router: Router,
    private lowResolutionService: LowResolutionService
  ) {
    super();
    this.lowResControl.setValue(lowResolutionService.getIsLowResModeEnabled());
  }

  ngOnInit() {
    this.initLoggedInUser();
    this.listenLowResModeChanges();
    return super.ngOnInit();
  }

  logout(): void {
    if (environment.keyCloakEnabled) {
      this.keycloakService.logout();
    } else {
      this.authService.logout(true);

      this.sideMenuService.toggleSideMenu(false);
      this.router.navigate(['/auth-user']);
    }
  }

  private initLoggedInUser(): void {
    this.authService.loggedInUserChange.pipe(untilDestroyed(this)).subscribe((user) => {
      this.loggedInUser = user;
    });
  }

  private listenLowResModeChanges() {
    this.lowResolutionService
      .getLowResChanges()
      .pipe(untilDestroyed(this))
      .subscribe((value: boolean) => this.lowResControl.setValue(value, { emitEvent: false }));

    this.lowResControl.valueChanges.pipe(untilDestroyed(this)).subscribe((value: boolean) => {
      this.lowResolutionService.setLowResModeEnabledState(value);
    });
  }

  /* eslint-disable @typescript-eslint/member-ordering */
  ngOnDestroy() {
    return super.ngOnDestroy();
  }
}
